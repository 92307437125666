import React from 'react';
import Layout from '../components/Layout';

const cookiePolicy = (): React.ReactElement => {
  return (
    <Layout>
      <div id="cookiePolicy" className="section">
        <a id="main-content" tabIndex={-1} />
        <div data-drupal-messages-fallback className="hidden" />
        <div
          id="block-suop-bootstrap-sass-page-title"
          className="block block-core block-page-title-block"
        >
          <div className="content">
            <h1 className="title">
              <span className="field field--name-title field--type-string field--label-hidden">
                Política de Cookies
              </span>
            </h1>
          </div>
        </div>
        <div
          id="block-suop-bootstrap-sass-content"
          className="block block-system block-system-main-block"
        >
          <div className="content">
            <article
              data-history-node-id={18}
              role="article"
              className="node node--type-page node--view-mode-full clearfix"
            >
              <header></header>
              <div className="node__content clearfix">
                <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                  <p>
                    <strong>
                      Por favor leia atentamente a presente Política de Cookies antes de utilizar os
                      Serviços WALKING DIAGONAL LDA, a qual é parte integrante da Política de
                      Privacidade.
                    </strong>
                  </p>
                  <p>Última atualização em 12/01/2021</p>
                  <p>&nbsp;</p>
                  <p>
                    A <strong>WALKING DIAGONAL LDA</strong> utiliza no seu website e aplicação
                    cookies para melhorar a sua experiência como utilizador e permitir realizar
                    determinadas operações de forma segura.
                  </p>
                  <p>
                    O uso da App em tablets e telemóveis é feito através de tokens que desempenham
                    uma função semelhante à dos cookies, mas são apenas utilizados na App. Por uma
                    questão de simplicidade, utilizaremos apenas os termos “cookies” e “sites” na
                    presente Política de Cookies, mas esta é igualmente aplicável aos referidos
                    tokens.
                  </p>
                  <p>
                    Ao navegar no nosso website e aplicação concorda com a utilização dos cookies
                    nos termos definidos nesta Política de Cookies. Quaisquer alterações à presente
                    Política serão publicadas nesta página, pelo que se recomenda que a mesma seja
                    visitada regularmente.
                  </p>
                  <p>&nbsp;</p>
                  <h3>1. O que são cookies?</h3>
                  <p>
                    Os cookies são pequenos ficheiros (software) que são armazenados nos
                    Dispositivos do (computador, tablet, telemóvel, ou outro dispositivo móvel) do
                    utilizador ou público em geral, através do seu browser, por ocasião do acesso ao
                    website e/ou App.
                  </p>
                  <p>
                    Os cookies incluem um ou mais identificadores únicos que são enviados para o
                    browser ou outra parte do Dispositivo do utilizador ou público em geral e aí
                    ficam instalados. Cada site e aplicação dispõe da capacidade de enviar os seus
                    próprios cookies para o browser utilizado para lhes aceder, caso as preferências
                    do seu browser assim o permitam. Para proteção da sua privacidade, o seu browser
                    só permite que um site aceda aos cookies que já tenha enviado para si,
                    ficando-lhe vedado o acesso aos cookies que tenham sido enviados por outros
                    sites.
                  </p>
                  <p>
                    Para saber mais sobre o que são cookies, bem como de que modo pode geri-los ou
                    impedir a sua utilização no seu Dispositivo, visite www.allaboutcookies.org, no
                    qual poderá encontrar informações sobre como modificar as configurações
                    aplicáveis ao browser que utilizar.
                  </p>
                  <p>&nbsp;</p>
                  <h3>2. Para que servem os cookies?</h3>
                  <p>
                    A colocação de cookies torna a utilização dos Serviços mais simples e eficiente
                    e ajudará a Plataforma (website e App) a reconhecer o seu Dispositivo na próxima
                    vez que a visitar.
                  </p>
                  <p>
                    Os cookies utilizados pela WALKING DIAGONAL LDA retêm, por exemplo, informação
                    relacionada com códigos identificadores do utilizador, e informação de sessão,
                    para garantir a segurança e experiência de navegação. Assim, os cookies podem
                    facilitar a sua próxima visita e tornar os sites mais úteis para si.
                  </p>
                  <p>
                    Para instalar determinados cookies no seu Dispositivo necessitamos do seu
                    consentimento prévio, razão pela qual existe um aviso sobre este assunto no
                    site.&nbsp;
                  </p>
                  <p>
                    A qualquer momento, o utilizador pode, através do seu navegador de internet
                    (browser), decidir ser notificado sobre a receção de cookies, bloquear a
                    respetiva entrada no seu Dispositivo ou remover os cookies previamente
                    instalados.
                  </p>
                  <p>
                    A recusa de uso de cookies no site pode resultar na impossibilidade de aceder a
                    algumas das suas áreas.
                  </p>
                  <p>&nbsp;</p>
                  <h3>3. Porque utilizamos os cookies?</h3>
                  <p>
                    A utilização de cookies na Internet é usual e não prejudica os dispositivos dos
                    utilizadores. Os cookies executam diversas funções, nomeadamente auxiliam os
                    responsáveis do website e App a perceber o modo como estes são utilizados,
                    facilitando a respetiva navegação, guardando as suas preferências e, de modo
                    geral, melhorando a sua experiência de utilização, servindo ainda para garantir
                    que a referida Plataforma lhe mostra conteúdo relevante.
                  </p>
                  <p>&nbsp;</p>
                  <h3>4. Que tipo de cookies utilizamos?</h3>
                  <p>
                    Os utilizadores podem configurar os seus dispositivos e os browsers que utilizam
                    para aceitarem todos ou alguns cookies, para os notificarem sempre que um cookie
                    é emitido, ou para nunca receberem cookies. Sendo cada browser/navegador
                    diferente, verifique o menu de ajuda do seu browser/navegador para aprender a
                    alterar as suas preferências em matéria de cookies.
                    <br />
                    Relativamente à duração, os cookies utilizados pela WALKING DIAGONAL LDA podem
                    ser:
                  </p>
                  <ol>
                    <li>
                      <strong>Cookies permanentes</strong> - são cookies que ficam armazenados no
                      browser utilizado nos seus dispositivos eletrónicos e que são utilizados
                      sempre que faz uma nova visita a um dos nossos sites ou aplicações.
                      <br />
                      <br />
                      Nestes cookies são mantidos os seguintes dados:
                      <br />
                      <u>Identificação do dispositivo</u> – chave únicas geradas e utilizadas por
                      terceiros para garantir a correta identificação da combinação app-dispositivo.
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <strong>Cookies de sessão</strong> - são cookies temporários que permanecem
                      nos cookies do seu navegador de internet (browser) até sair do site. A
                      informação que mantêm permite gerir, de forma segura, as diversas operações
                      realizadas por si durante uma sessão de acesso à nossa Plataforma,
                      permitindo-nos identificar problemas e fornecer uma melhor experiência de
                      navegação.
                      <br />
                      <br />
                      Nestes cookies são mantidos os seguintes dados:
                      <br />
                      <u>Dados de Sessão</u> - código produzido internamente pela plataforma
                      tecnológica responsável pela disponibilização do website e App, e que, de
                      forma encriptada, gere um código temporário de sessão com vista a relacionar o
                      equipamento do utilizador com uma sessão aplicacional desta plataforma.
                    </li>
                  </ol>
                  <p>&nbsp;</p>
                  <p>
                    Relativamente à função, os cookies utilizados pela WALKING DIAGONAL LDA podem
                    ser:
                  </p>
                  <ol>
                    <li>
                      <strong>Cookies essenciais</strong> - Alguns cookies são essenciais para
                      aceder a áreas específicas do nosso wesite e App. Permitem a navegação no
                      website e App e a utilização das suas aplicações, tal como o acesso a áreas
                      seguras através de login. Sem estes cookies, determinados serviços não podem
                      ser prestados. De um modo geral, não é necessário o consentimento do
                      utilizador para instalar cookies essenciais no seu Dispositivo quando acede ao
                      website ou App.
                      <br />
                      <br />
                      Nestes cookies são mantidos os seguintes dados:
                      <br />
                      <u>- User ID </u>- Código gerido pela WALKING DIAGONAL LDA e que identifica
                      inequivocamente um utilizador do website e App.
                      <br />
                      <u>- Perfil de Utilizador</u> - Identificação do perfil de autorização que
                      está associado ao UserID e que permite gerir o acesso deste às funcionalidades
                      disponíveis num determinado site.
                    </li>
                  </ol>
                  <p>
                    O nosso website poderá conter links para outros sítios de Internet ou
                    aplicações, incluindo dos nossos parceiros. Caso aceda a um desses links ou
                    aplicações, por favor note que cada um terá a sua própria política de cookies e
                    não nos responsabilizamos por essas políticas. Por favor leia as políticas de
                    cookies de outros sítios de Internet ou aplicações antes de utilizar os
                    mesmos.&nbsp;
                  </p>
                  <p>
                    Na tabela abaixo, poderá analisar os cookies que instalamos nos seus
                    Dispositivos:
                  </p>
                  <table
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                    }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">Nome / Tipo de cookies</th>
                        <th scope="col">Finalidade</th>
                        <th scope="col">Duração (data de validade)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>has_js / Essencial</td>
                        <td>
                          Indificar se o browser do utilizador permite ou não o uso de JavaScript.
                        </td>
                        <td>Expira com o fecho do browser</td>
                      </tr>
                      <tr>
                        <td>PHPSESSID / Essencial</td>
                        <td>
                          Cookie de sessão para o PHP, associado a conteúdo incorporado no domínio.
                        </td>
                        <td>Expira com o fecho do browser</td>
                      </tr>
                      <tr>
                        <td>X-CSRF-Token / Essencial</td>
                        <td>Cookie de sessão.</td>
                        <td>Expira com o terminar de sessão na app.</td>
                      </tr>
                      <tr>
                        <td>devicetoken / Essencial</td>
                        <td>
                          Chave única, para combinação app-dispositivo, que permite que gateways e
                          provedores de notificação push encaminhem mensagens a quem se destinam.
                        </td>
                        <td>Permanente</td>
                      </tr>
                      <tr>
                        <td>FCMToken / Essencial</td>
                        <td>
                          ID emitido pelos servidores de conexão GCM para a app que permite receber
                          mensagens.
                        </td>
                        <td>Permanente</td>
                      </tr>
                      <tr>
                        <td>FBSDKAccessToken / Essencial</td>
                        <td>Token de acesso imutável para usar os serviços do Facebook.</td>
                        <td>Permanente</td>
                      </tr>
                      <tr>
                        <td>HTTPCookieStorage / Essencial</td>
                        <td>Um contentor que gere o armazenamento de cookies.</td>
                        <td>Permanente</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>&nbsp;</p>
                  <h3>5. O uso de cookies pode ser bloqueado ou limitado?</h3>
                  <p>
                    Como referido, os cookies contribuem para uma melhor experiência de utilização
                    do nosso site. Depois de autorizar o uso de cookies, será dada a possibilidade
                    ao público em geral que visite o site que não seja utilizador da Plataforma a
                    possibilidade de os desativarem.
                  </p>
                  <p>
                    Já os utilizadores autenticados na App não terão a possibilidade de desativar os
                    cookies, pois os mesmos são essenciais para o funcionamento da App.
                  </p>
                  <p>
                    No entanto, podem os mesmos bloquear os referidos cookies, continuando a
                    conseguir visualizar a totalidade da página do website, sem, contudo,
                    conseguirem aceder à App.
                  </p>
                  <p>&nbsp;</p>
                  <h3>6. Como é que os cookies devem ser geridos?</h3>
                  <p>
                    Todos os navegadores de internet (browsers) permitem ao utilizador aceitar,
                    recusar ou apagar cookies, através da gestão das definições no browser.
                  </p>
                  <p>
                    A desativação de cookies pode impedir que alguns serviços da internet funcionem
                    corretamente, afetando, parcial ou totalmente, a navegação e funcionalidades do
                    site.
                  </p>
                  <p>&nbsp;</p>
                  <h3>7. Contactos</h3>
                  <p>
                    Para quaisquer informações adicionais, ou para exercer os seus direitos, por
                    favor contacte-nos em qualquer momento através dos seguintes canais:
                  </p>
                  <p>info@suopapp.com</p>
                  <p>www.suopapp.com</p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default cookiePolicy;
